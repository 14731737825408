<template>
	<div class="city_box">
		<TableList :columns="columns" :get_table_list="this.get_table_list" :slot_table_list="['operation','price']"
			:submit_preprocessing="submit_preprocessing" ref="list" :rowKey="'id'">

			<template slot="operation" slot-scope="data" v-if="data.record.flag!='other'">
				<span>
					<a @click="edit(data.record)">编辑</a>
				</span>
			</template>
		</TableList>
		<EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible" class="edit_info">
			<template slot='area'>
				<div class="user_number mask_input">
					<a-select :defaultValue="hotel_area_data" mode="multiple" placeholder='点击选择调整地区'
						@change="handle_area2">
						<a-select-opt-group v-for='(area,index) in getArea_data' :key='index'>
							<span slot="label">{{area.label}}</span>
							<a-select-option :value="area_item.label" :key='index' v-for='area_item in area.children '>
								{{area_item.label}}
							</a-select-option>
						</a-select-opt-group>
					</a-select>
				</div>

			</template>

		</EditPop>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import SelectUser from "@/components/SelectUser";
	import {
		hotelLevelList,
		saveHotelLevel,
		delHotelLevel,
		CityManageList,
		getArea,
		cityManageDetail,
		cityManageSave
	} from "@/api/evection";
	import {
		getUserList
	} from "@/api/personnel";

	const columns = [{
			title: "ID",
			dataIndex: "id"
		},
		{
			title: "城市级别",
			dataIndex: "title"
		},
		{
			title: "城市名字",
			dataIndex: "area",
			scopedSlots: {
				customRender: "area"
			}
		},

		{
			title: "最后更新时间",
			dataIndex: "update_time"
		},
		{
			title: "操作",
			dataIndex: "operation",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];



	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
			SelectUser
		},
		data() {
			return {
				hotel_area_data: [],
				config: this.$config,
				get_table_list: CityManageList,
				get_pop_table_list: getUserList,
				submit_preprocessing: {
					array_to_string: ['department_id', 'group_id']
				},
				columns,
				price: 0,
				selectedRowKeys: [],
				loading: false,
				visible: false,
				getArea_data: [],
				form_data_seo: {
					list: [

						{
							type: "text",
							name: "keyword",
							title: "级别名称",
							placeholder: "请输入级别名称",
							options: {}
						},
						{
							type: "range_date",
							name: "range_date",
							title: "最后更新时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},

					],
					...this.$config.form_data_seo
				},
				form_data: {
					title: '修改城市',
					list: [{
							type: 'text',
							name: 'title',
							title: '城市级别',
							disabled: true,
							options: {
								initialValue: ''
							},
						},
						{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {
								initialValue: ''
							},
						},
						{
							type: 'slot',
							name: 'area',
							title: '选择城市',
							placeholder: "级别名称需要便于辨认，例如：300元以下",
							options: {
								initialValue: [],
								rules: [{
									required: true,
									message: "请输入级别名称"
								}]
							},
						},

					]
				},
				area_data2: []
			};
		},


		async created() {
			setTimeout(()=>{
				this.get_getArea()
			},1000)
			
		},
		methods: {
			submit(data) {
				let obj = {
					...data.values,
					area: this.hotel_area_data.join(',')
				}
				cityManageSave({
					data: obj,
					info: true
				}).then(res => {
					let _this = this;
					this.visible = false;
					this.$refs.list.get_list();
				})

			},
			add() {
				this.form_data.title = "添加"
				this.form_data.list.forEach(item => {
					item.options.initialValue = "";
				})
				this.price = 0
				this.visible = true;
			},
			del(record) {
				cityManageDetail({
					data: {
						id: record.id
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			handle_area2(data) {
				this.hotel_area_data = data
			},
			get_getArea() {
				getArea().then(res => {
					this.getArea_data = res.data.list
				})
			},
			edit(data) {
				cityManageDetail({
					data: {
						id: data.id
					},
				}).then(res => {
					this.hotel_area_data=[]
					let arr = res.data.list.area.split(',')
					this.hotel_area_data = res.data.list.area.split(',');
					this.$set(this,'hotel_area_data',arr)
					this.form_data.list.forEach(item => {
						if (item.name == 'title') {
							item.options.initialValue = res.data.list.title;
						}
						if (item.name == 'id') {
							item.options.initialValue = res.data.list.id;
						}
						if (item.name == 'area') {
							item.options.initialValue  = arr;
						}
					})
					this.visible = true;
				})
			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.city_box {
		.price_input {
			width: 150px;
			margin-right: 20px;
		}

		.edit_info {
			.user_number {
				margin: 0 auto;
				display: flex;
				color: #000;
			}

			.mask_input {
				display: flex;
			}

			.label_p {
				width: 80px;
			}
		}

	}
</style>
